/* raleway-regular - latin */

@font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    src: url('./fonts/raleway-v17-latin-regular.eot');
    src: local(''), url('./fonts/raleway-v17-latin-regular.eot?#iefix') format('embedded-opentype'), url('./fonts/raleway-v17-latin-regular.woff2') format('woff2'), url('./fonts/raleway-v17-latin-regular.woff') format('woff'), url('./fonts/raleway-v17-latin-regular.ttf') format('truetype'), url('./fonts/raleway-v17-latin-regular.svg#Raleway') format('svg');
}

/* raleway-500 - latin */

@font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    src: url('./fonts/raleway-v17-latin-500.eot');
    src: local(''), url('./fonts/raleway-v17-latin-500.eot?#iefix') format('embedded-opentype'), url('./fonts/raleway-v17-latin-500.woff2') format('woff2'), url('./fonts/raleway-v17-latin-500.woff') format('woff'), url('./fonts/raleway-v17-latin-500.ttf') format('truetype'), url('./fonts/raleway-v17-latin-500.svg#Raleway') format('svg');
}

/* raleway-600 - latin */

@font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    src: url('./fonts/raleway-v17-latin-600.eot');
    src: local(''), url('./fonts/raleway-v17-latin-600.eot?#iefix') format('embedded-opentype'), url('./fonts/raleway-v17-latin-600.woff2') format('woff2'), url('./fonts/raleway-v17-latin-600.woff') format('woff'), url('./fonts/raleway-v17-latin-600.ttf') format('truetype'), url('./fonts/raleway-v17-latin-600.svg#Raleway') format('svg');
}

/* cabin-regular - latin */

@font-face {
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 400;
    src: url('./fonts/cabin-v15-latin-regular.eot');
    src: local(''), url('./fonts/cabin-v15-latin-regular.eot?#iefix') format('embedded-opentype'), url('./fonts/cabin-v15-latin-regular.woff2') format('woff2'), url('./fonts/cabin-v15-latin-regular.woff') format('woff'), url('./fonts/cabin-v15-latin-regular.ttf') format('truetype'), url('./fonts/cabin-v15-latin-regular.svg#Cabin') format('svg');
}


#root {
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
}

/* OLD BODY */

body {
    height: 100%;
    margin: 0;
    overflow-x: hidden;
}

.frozen {
    overflow: hidden;
    position: fixed;
}

html {
    background-color: rgb(3, 60, 79);
}

/* @supports(padding: max(0px)) {

    body {
        padding-left: max(0px, env(safe-area-inset-left));
        padding-right: max(0px, env(safe-area-inset-right));
    }

    body, header, footer {
        padding-left: max(0px, env(safe-area-inset-left));
        padding-right: max(0px, env(safe-area-inset-right));
    }
} */

ul {
    margin-top: 0;
}

/* Used to set placeholder text color for contact form's input fields (except textarea, handled below) */

input.form-control::placeholder {
    color: #CCC;
    opacity: 1;
}

/* Used to set placeholder text color for contact form's textarea; method above didn't work for this field */

#message::placeholder {
    color: #CCC;
    opacity: 1;
}

.navLinkText {
    font-family: Avenir;
    /* font-size: 15px; */
    font-size: 18px;
}

.landingPageButton {
    /* color: gray; */
    /* color: #ff0000;   */
    background-color: rgba(148, 159, 182, 0.2);
    text-align: center;
    padding: 4px 8px 4px 8px;
    border: none;
    border-radius: 2px;
    transition-duration: 0.4s;
    font-family: Avenir;
    font-weight: 300;
}

.landingPageButton:hover {
    background-color: rgba(148, 159, 182, 0.5);
    color: gray;
}

.landingPageCustomText {
    font-family: Avenir;
}

.navbarHeaderText {
    /* font-size: 40pt; */
    font-family: 'Raleway';
    font-weight: 400;
    color: rgba(225, 226, 221, 1.0)
    /* font-weight: 500; */
}

.navbarSubtitleText {
    /* font-size: 13pt; */
    font-family: 'Cabin';
    font-weight: 200;
    color: rgba(225, 226, 221, 1.0)
}

/* Photo Grid */

.row {
    display: flex;
    flex-wrap: wrap;
    padding: 0 4px;
}

/* Create four equal columns that sits next to each other */

.column {
    flex: 25%;
    max-width: 25%;
    padding: 0 4px;
}

.column img {
    margin-top: 8px;
    vertical-align: middle;
    width: 100%;
}

/* Responsive layout - makes a two column-layout instead of four columns */

@media screen and (max-width: 800px) {
    .column {
        flex: 50%;
        max-width: 50%;
    }
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */

@media screen and (max-width: 600px) {
    .column {
        flex: 100%;
        max-width: 100%;
    }
}

/* Navbar header text & logo gif */

@media (min-width: 0px) and (max-width: 767px) {
    .navbarHeaderText {
        font-size: 6.5vw;
    }
    .navbarSubtitleText {
        font-size: 3.26vw;
    }
    #logo-gif {
        width: 16.3vw;
        height: 16.3vw;
    }
}

@media (min-width: 768px) {
    .navbarHeaderText {
        font-size: 50px;
    }
    .navbarSubtitleText {
        font-size: 25px;
    }
    #logo-gif {
        width: 125px;
        height: 125px;
    }
}

/* New carousel CSS */

.carousel {
    width: auto;
    height: auto;
}

.carousel-item>img {
    width: 100%;
    display: block;
    /* height: auto;     */
}

/* Carousel (working for non-reactstrap carousel) */

.carousel-control-prev-icon {
    filter: invert(1);
    width: 30px;
    height: 30px;
}

.carousel-control-next-icon {
    filter: invert(1);
    width: 30px;
    height: 30px;
}

.carousel-caption {
    filter: invert(1);
}

.carousel-indicators .active {
    background-color: #999999;
}

.carousel-indicators li {
    background-color: #CCCCCC;
    top: 20px;
}

p.customText {
    font-family: Avenir;
}

p.customTextTwo {
    font-family: 'Raleway';
    font-size: 25px;
    font-weight: 400;
    color: rgba(225, 226, 221, 1.0);
}

p.customHeader {
    font-family: 'Raleway';
    font-size: 34px;
    font-weight: 500;
    color: rgba(225, 226, 221, 1.0);
}

div.mediaBox {
    width: 95%;
    padding-left: 8px;
    padding-right: 8px;
    border-style: solid;
    border-width: thin;
    border-color: #000000;
    border-radius: 5px;
}

/* CENTERS THE NAV BAR */

@media (min-width: 768px) {
    .navbar-nav.navbar-center {
        position: absolute;
        left: 50%;
        transform: translatex(-50%);
    }
}

/* NEW: Floating Button */

.floating-button {
    position: fixed;
    top: 3vh;
    right: 1.5vw;
    z-index: 999;
}

/* END NEW (Floating Button) */

/* SETS PHOTO GALLERY IMAGE MAX HEIGHT */

.react-images__view-image {
    max-height: 75vh !important;
}

.react-datepicker-wrapper {
    width: 256px;
}

.react-datepicker-wrapper input {
    width: 256px;
}

.fixed-ratio {
    display: block;
    position: relative;
    height: 0;
    overflow: hidden;
}

.fixed-ratio-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-width: inherit;
}

.fixed-ratio-16by9 {
    /* ratio: 100% / (16 / 9) = */
    padding-bottom: 56.25%;
}

.fixed-ratio-3by4 {
    /* ratio: 100% / (16 / 9) = */
    padding-bottom: 133.33%;
}

a.textLink {
    text-decoration: none !important;
}

a.textLink:hover {
    color: rgba(180, 180, 180, 1.0)!important;
}

/* KEEPS FOOTER ON BOTTOM OF PAGE (regardless of height of page content) */

/* html,
body {
 height: 100%;
 position: relative;
} */

#root {
    min-height: 97.5vh;
    overflow: hidden;
    display: block;
    position: relative;
    padding-bottom: 0px;
}

.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
}

.frozen {
    overflow: hidden;
}

.animatedDropdownItem {
    animation: animatedDropdownItemAnimation 0.3s forwards;
}

@keyframes animatedDropdownItemAnimation {
    0% {
        font-size: 0px;
    }
    100% {
        font-size: 20px;
    }
}

.morozDigital {
    font-family: 'Raleway';
    font-size: 14px;
    font-weight: 400;
}


.morozDigitalLogoLink {
    color: rgba(225, 226, 221, 1.0) !important;
    text-decoration: none !important;
}



