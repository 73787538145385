/* Position and sizing of burger button */

.bm-burger-button {
    position: absolute;
    width: 24px;
    height: 20px;
    right: 36px;
    top: 36px;
    display: none;
}

/* Color/shape of burger icon bars */

.bm-burger-bars {
    background: rgb(225, 226, 220);
    display: none;
}

/* Color/shape of burger icon bars on hover*/

.bm-burger-bars-hover {
    background: white;
}

/* Position and sizing of clickable cross button */

.bm-cross-button {
    position: absolute;
    display: none;
}

/* Color/shape of close button cross */

.bm-cross {
    background: #bdc3c7;
    display: none;
}

/*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */

.bm-menu-wrap {
    position: fixed;
    height: 100%;
    bottom: 0;
    left: 0;
    transition-duration: 0.3s !important;
}

/* General sidebar styles */

.bm-menu {
    background: rgb(57, 108, 141);
    /* padding: 2.5em 1.5em 0; */
    font-size: 1.15em;
    margin: 0px;
    overflow-y: scroll;
}

/* Morph shape necessary with bubble or elastic */

.bm-morph-shape {
    fill: #373a47;
}

/* Wrapper for item list */

.bm-item-list {
    text-align: center;
    color: #b8b7ad;  
    /* margin-top: 25vh;
    margin-bottom: 35vh; */
    outline: none;    
    border: 1px solid rgb(57, 108, 141);
    /* border: 1px solid red;     */
    overflow-y: scroll;
}

/* Individual item */

.bm-item {
    display: inline-block;
    color: white;
    transition-duration: 0.6s !important;
    outline: none;    
}

/* Styling of overlay */

.bm-overlay {    
    background: rgba(0, 0, 0, 0.3);
    bottom: 0;
    left: 0;
    overflow: hidden;
}